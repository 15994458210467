import {
  Box,
  Flex,
  Text,
  Button,
  Heading,
  HStack,
  GridItem,
  Grid,
  Tag,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoGlobe, IoLogoCodepen, IoLogoGithub } from "react-icons/io5";
import projects from "../data/projects";

const options = [
  {
    id: 1,
    label: "Code",
  },
  {
    id: 2,
    label: "Graphics",
  },
  {
    id: 3,
    label: "Mixed",
  },
];

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <Box
      w={"83%"}
      maxWidth={"100%"}
      margin="0 auto"
      marginY={32}
      className="js-portfolio"
    >
      <Flex alignItems="center" mb={16}>
        <Heading
          flex="1"
          fontSize="4xl"
          color="brand.main"
          bgGradient={"radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"}
          backgroundSize={" 100% 200%"}
          backgroundPosition={"top left"}
          bgClip="text"
        >
          My Work
        </Heading>

        <HStack spacing={8}>
          {options.map((item) => {
            return (
              <Button
                position="relative"
                p="0"
                bg="none"
                borderRadius="0"
                borderBottom={"4px solid transparent"}
                color={item.id === activeTab ? "black" : "gray.500"}
                _hover={{
                  color: "black",
                }}
                _active={{
                  color: "black",
                }}
                onClick={() => {
                  setActiveTab(item.id);
                }}
              >
                {item.label}
                <Box
                  position="absolute"
                  bottom={-2}
                  left="0"
                  w="100%"
                  h={2}
                  borderRadius={8}
                  bgGradient={"linear(to-br, #5400ea, #e15f43)"}
                  transition="0.3s ease all"
                  opacity={item.id === activeTab ? 1 : 0}
                ></Box>
              </Button>
            );
          })}
        </HStack>
      </Flex>
      <Box>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
          gap={8}
        >
          {projects
            .filter((item) => item.type === activeTab)
            .map((item) => {
              return (
                <GridItem>
                  <Flex height="100%">
                    <Box
                      background="gray.700"
                      width="75%"
                      minH="300px"
                      height="100%"
                      mr="-25%"
                      opacity="1"
                      borderRadius={16}
                      backgroundImage={item.image}
                      backgroundSize="cover"
                      backgroundPosition="center"
                      boxShadow="0 0 20px rgba(84, 0, 234, 0.15)"
                    ></Box>
                    <Box
                      background="white"
                      boxShadow="0 0 20px rgba(84, 0, 234, 0.15)"
                      width={{ base: "100%", lg: "70%" }}
                      minH="100px"
                      marginY={4}
                      opacity="1"
                      borderRadius={16}
                      overflow="hidden"
                    >
                      <Flex height="100%" flexDir="column">
                        <Box flex="1" p={8}>
                          <Heading
                            fontSize="xl"
                            mb={2}
                            bgGradient={"linear(to-br, #5400ea, #e15f43)"}
                            bgClip="text"
                          >
                            {item.title}
                          </Heading>
                          <Text mb={4}>{item.desc}</Text>
                          {item.tools.map((item) => {
                            return (
                              <Tag
                                size="sm"
                                mr={2}
                                mb={2}
                                color="white"
                                bgGradient={"linear(to-br, #5400ea, #e15f43)"}
                                bgSize="150% 100%"
                              >
                                {item}
                              </Tag>
                            );
                          })}
                        </Box>
                        {!item.wip && (
                          <Flex
                            borderTop="1px solid gray"
                            borderColor="gray.200"
                          >
                            {item.repoLink.length > 0 && (
                              <Link
                                href={item.repoLink}
                                target="_blank"
                                flex="1"
                                p={4}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                _hover={{
                                  background: "gray.200",
                                  cursor: "pointer",
                                }}
                                borderRight="1px solid gray"
                                borderRightColor="gray.200"
                              >
                                {item.repoLink.includes("codepen") ? (
                                  <IoLogoCodepen size={20} />
                                ) : (
                                  <IoLogoGithub size={18} />
                                )}
                                <Box lineHeight={0} mb={-1} ml={2}>
                                  Code
                                </Box>
                              </Link>
                            )}
                            {item.link.length > 0 && (
                              <Link
                                href={item.link}
                                target="_blank"
                                flex="1"
                                p={4}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                _hover={{
                                  background: "gray.200",
                                  cursor: "pointer",
                                }}
                              >
                                {" "}
                                <IoGlobe />
                                <Box lineHeight={0} mb={-1} ml={2}>
                                  Live
                                </Box>
                              </Link>
                            )}
                          </Flex>
                        )}
                        {item.wip && (
                          <Box p={4} textAlign="center" fontStyle="italic">
                            Coming Soon
                          </Box>
                        )}
                      </Flex>
                    </Box>
                  </Flex>
                </GridItem>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};

export default Portfolio;
