import { useEffect } from "react";
import About from "./components/About";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import Portfolio from "./components/Portfolio";
import Skills from "./components/Skills";
import { Helmet } from "react-helmet";

function App() {
  useEffect(() => {
    document.title = "Victor Fuentes • Front-End Engineer & Visual Designer";
  }, []);
  return (
    <>
      <Helmet>
        <title>Victor Fuentes</title>
        <meta name="description" content="Portfolio of Victor Fuentes" />
      </Helmet>
      <Hero />
      <Portfolio />
      <About />
      <Skills />
      <Contact />
    </>
  );
}

export default App;
