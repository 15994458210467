import { Box, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import CustomIcon from "./CustomIcon";

const data = [
  {
    title: "10+ Years of Experience",
    body: "With a decade of experience as both an engineer and designer, I bring a wealth of expertise to every project. From the boring to exciting, I've probably ran into it.",
    icon: <CustomIcon type="code" />,
  },
  {
    title: "Multi-Disciplined",
    body: "With an understanding from concept to deliver, I use my cross-skill approach to bridge disciplines and unlock innovative solutions.",
    icon: <CustomIcon type="lab" />,
  },
  {
    title: "Learning Oriented",
    body: "As a self-taught professional, I'm quick to learn and eager to expand my skills. My forever-learner mindset has set me up for continuous growth and adaptability.",
    icon: <CustomIcon type="working" />,
  },
  {
    title: "Solution Driven",
    body: "With a passion for solving problems I pour myself into my work. Whether in a individual setting or a team setting, I'm always striving to find the best way to deliver value, contribute meaningfully while promote a positive culture.",
    icon: <CustomIcon type="remote" />,
  },
];

const About = () => {
  return (
    <Box w={"83%"} maxWidth={"100%"} margin="0 auto" marginY={32}>
      <Heading
        mb={16}
        fontSize="4xl"
        color="brand.main"
        bgGradient={"radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"}
        backgroundSize={" 100% 200%"}
        backgroundPosition={"top left"}
        bgClip="text"
      >
        About Me
      </Heading>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
        gap={16}
      >
        {data.map((item) => {
          return (
            <GridItem>
              <Flex>
                <Box mr={8}>
                  <Box
                    w={20}
                    h={20}
                    // background="gray.200"
                    borderRadius={80}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    opacity={0.5}
                  >
                    {item.icon}
                  </Box>
                </Box>
                <Box flex={1} pt={4}>
                  <Heading
                    fontSize="3xl"
                    color="brand.main"
                    bgGradient={
                      "radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"
                    }
                    backgroundSize={"200% 200%"}
                    backgroundPosition={"top left"}
                    bgClip="text"
                  >
                    {item.title}
                  </Heading>
                  <Text>{item.body}</Text>
                </Box>
              </Flex>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};

export default About;
