import { extendTheme } from "@chakra-ui/react";

const colors = {
  main: "#5400ea",
  side: "#e15f43",
};

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors: {
    black: "#171821",
    brand: {
      main: colors.main,
      side: colors.side,
    },
  },
  fonts: {
    heading: `Ezra, sans-serif`,
    body: `Ezra, sans-serif`,
  },
  components: {
    Text: {
      baseStyle: {
        color: "#242533",
      },
    },
    Button: {
      variants: {
        rounded: {
          borderRadius: "100px",
          paddingX: 8,
        },
      },
    },
    UnorderedList: {
      variants: {
        skills: {
          border: "1px solid red",
        },
      },
    },
    ListItem: {},
  },
});

export default theme;
