import { Box, Flex, Heading, List, ListItem } from "@chakra-ui/react";

const tools = [
  {
    label: "HTML",
  },
  {
    label: "CSS",
  },
  {
    label: "Javascript",
  },
  {
    label: "AngularJS",
  },
  {
    label: "React",
  },
  {
    label: "PHP",
  },
  {
    label: "Node",
  },
  {
    label: "AWS",
  },
  {
    label: "Adobe CS",
  },
  {
    label: "Git + Github",
  },
  {
    label: "Socket.io",
  },
  {
    label: "Canvas API",
  },
  {
    label: "MySQL",
  },
];
const skills = [
  {
    label: "Software Engineering",
  },
  {
    label: "UX/UI Design",
  },
  {
    label: "Graphic Design",
  },
  {
    label: "Problem Solving",
  },
  {
    label: "Debugging",
  },
  {
    label: "Motion Design",
  },
  {
    label: "Identity Design",
  },
  {
    label: "Team Player",
  },
  {
    label: "Autodidactic",
  },
  {
    label: "API Design",
  },
  {
    label: "Accessability",
  },
  {
    label: "QA Testing",
  },
];

const Skills = () => {
  return (
    <Box background="black" paddingTop={24} paddingY={{ base: 16 }}>
      <Box w={{ base: "88%", lg: "83%" }} maxWidth={"100%"} margin="0 auto">
        <Flex flexDir={{ base: "column", lg: "row" }}>
          <Box
            padding={16}
            background="white"
            flex="1"
            borderRadius={24}
            mr={8}
            mb={{ base: 16, lg: 0 }}
            width={{ base: "100%", lg: "auto" }}
          >
            <Heading
              color="brand.main"
              bgGradient={"radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"}
              backgroundSize={"200% 200%"}
              backgroundPosition={"top left"}
              bgClip="text"
              mb={8}
            >
              Tools
            </Heading>
            <List spacing={2}>
              {tools.map((item) => (
                <ListItem>
                  <Flex alignItems="center">
                    <Box
                      color="gray.600"
                      fontSize="xl"
                      fontWeight="bold"
                      pl={0}
                      transition="0.3s ease all"
                      _hover={{ paddingLeft: 4, color: "gray.900" }}
                      flex="1"
                    >
                      {item.label}
                    </Box>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box
            padding={16}
            background="white"
            flex="1"
            borderRadius={24}
            width={{ base: "100%", lg: "auto" }}
          >
            <Heading
              color="brand.main"
              bgGradient={"radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"}
              backgroundSize={"200% 200%"}
              backgroundPosition={"top left"}
              bgClip="text"
              mb={8}
            >
              Skills
            </Heading>
            <List spacing={2}>
              {skills.map((item) => (
                <ListItem>
                  <Flex alignItems="center">
                    <Box
                      color="gray.600"
                      fontSize="xl"
                      fontWeight="bold"
                      pl={0}
                      transition="0.3s ease all"
                      _hover={{ paddingLeft: 4, color: "gray.900" }}
                      flex="1"
                    >
                      {item.label}
                    </Box>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Skills;
