import { Box, Flex, Heading, Text } from "@chakra-ui/layout";
import { TiArrowDown } from "react-icons/ti";
import {
  IoLogoCss3,
  IoLogoFigma,
  IoLogoHtml5,
  IoLogoJavascript,
  IoLogoNodejs,
  IoLogoReact,
} from "react-icons/io5";
import pfpImage from "../assets/images/pfp.jpg";
import { SiAdobe } from "react-icons/si";

const logos = [
  {
    icon: <IoLogoHtml5 size={52} />,
    color: "#3557f0",
    label: "HTML",
  },
  {
    icon: <IoLogoCss3 size={52} />,
    color: "#ff911c",
    label: "CSS",
  },
  {
    icon: <IoLogoJavascript size={52} />,
    color: "#ffe138",
    label: "Javascript",
  },
  { icon: <IoLogoNodejs size={52} />, color: "#33ff1c", label: "Node.js" },
  { icon: <IoLogoReact size={52} />, color: "#1cceff", label: "React" },
  {
    icon: <SiAdobe size={44} />,
    color: "#ff331c",
    label: "Adobe Creative Suite",
  },
  { icon: <IoLogoFigma size="52" />, color: "#bf1cff", label: "Figma" },
];

const Hero = () => {
  const arrowClickHandler = () => {
    const elm = document.querySelector(".js-portfolio");
    let elmPos = elm.getBoundingClientRect().top;
    let scrollPos = window.scrollY || window.pageYOffset;
    let topVal = elmPos + scrollPos;
    window.scrollTo({
      left: 0,
      top: topVal - parseInt(getComputedStyle(elm).marginTop, 10),
      behavior: "smooth",
    });
  };
  return (
    <Box background="black" position="relative" overflow="hidden">
      <Flex
        w={"83%"}
        maxWidth={"100%"}
        minH="100vh"
        margin="0 auto"
        paddingY={16}
        alignItems="center"
        justifyContent="center"
        color="white"
        flexDirection={{ base: "column", md: "row" }}
        position="relative"
        zIndex={2}
      >
        <Box
          flex="2"
          minWidth="20%"
          color="white"
          order={{ base: 2, md: 1 }}
          lineHeight={1}
        >
          <Text fontSize={34} opacity={0.5} mb={4} color="white">
            Hi, I'm
          </Text>
          <Heading fontSize={40} pr={16} mb={8}>
            <Text
              color="brand.main"
              bgGradient={"radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"}
              backgroundSize={" 150% 200%"}
              backgroundPosition={"top left"}
              bgClip="text"
              fontSize={{ base: 82 }}
              lineHeight={1}
            >
              Victor Fuentes
            </Text>
            A Front-End Engineer <br />& Visual Designer
          </Heading>
          <Box aria-hidden>
            {logos.map((item) => (
              <Box
                w={16}
                h={16}
                borderRadius={60}
                display="inline-block"
                color="white"
                transition="0.3s ease all"
                _hover={{
                  color: item.color,
                  transform: "scale(1.1)",
                }}
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  aria-label={item.label}
                >
                  {item.icon}
                </Flex>
              </Box>
            ))}
          </Box>
          <Box mt={8} color="white" fontsize={64}>
            <Box
              display="inline"
              filter="blur(3px)"
              _hover={{
                filter: "blur(0px)",
                cursor: "pointer",
              }}
              transition="0.3s ease all"
              onClick={arrowClickHandler}
            >
              <TiArrowDown
                aria-label="Scroll down"
                style={{ display: "inline" }}
                size={64}
              />
            </Box>
          </Box>
        </Box>
        <Box
          aria-hidden
          flex="1"
          height="400px"
          minH="200px"
          width="min(200px)"
          mb={{ base: 16, md: 0 }}
          background="gray.200"
          backgroundImage={pfpImage}
          backgroundSize="cover"
          backgroundPosition="center center"
          backgroundRepeat="none"
          borderRadius={40}
          order={{ base: 1, md: 2 }}
        ></Box>
      </Flex>
      <Box
        aria-hidden
        w="400px"
        h="400px"
        borderRadius="400px"
        background="blue"
        position="absolute"
        top={-8}
        right={-8}
        zIndex={1}
        filter="blur(200px)"
        bgGradient={"linear(to-br, #5400ea, #e15f43)"}
      ></Box>
    </Box>
  );
};

export default Hero;
