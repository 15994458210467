import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Stack,
  Link,
} from "@chakra-ui/react";
import {
  IoLogoBehance,
  IoLogoGithub,
  IoLogoLinkedin,
  IoMail,
} from "react-icons/io5";

const socialButtons = [
  {
    label: "LinkedIn",
    color: "#0077b5",
    icon: <IoLogoLinkedin />,
    link: "https://www.linkedin.com/in/vpfuentes/",
  },
  {
    label: "Github",
    color: "black",
    icon: <IoLogoGithub />,
    link: "https://github.com/ftairs",
  },
  {
    label: "Behance",
    color: "#053eff",
    icon: <IoLogoBehance />,
    link: "https://www.behance.net/ftairs",
  },
  {
    label: "Email",
    color: "gray",
    icon: <IoMail />,
    link: "mailto:victorp.fuentes@gmail.com",
  },
];

const Contact = () => {
  return (
    <Flex
      minH="100vh"
      width="100%"
      margin="0 auto"
      alignItems="center"
      justifyContent="center"
      backgroundColor="black"
      position="relative"
      overflow="hidden"
    >
      <Box
        background={"white"}
        w={{ base: "88%", lg: "83%" }}
        maxWidth={"100%"}
        p={16}
        borderRadius={40}
        zIndex={2}
      >
        <Stack spacing={8}>
          <Heading
            color="brand.main"
            bgGradient={"radial-gradient(circle, #e15f43 0%,  #5400ea 100%);"}
            backgroundSize={" 100% 200%"}
            backgroundPosition={"top left"}
            bgClip="text"
            fontSize={{ base: 64 }}
            lineHeight={1}
            pb={4}
            mb={-4}
            pr={16}
          >
            Ready to talk about how I can add value to your team?
          </Heading>
          <Text fontSize={"xl"} mb={4}>
            I'm currently seeking a new role as a{" "}
            <span style={{ color: "#e15f43" }}>Software Engineer</span> and
            would be delighted to talk about how I can bring value to your team.
            I believe in the power of networking and collaboration, and am
            excited to connect with fellow professionals, hiring managers,
            developers and designers of all sorts.
          </Text>
          <Box>
            {socialButtons.map((item) => {
              return (
                <Button
                  as={Link}
                  href={item.link}
                  target="_blank"
                  variant="rounded"
                  mr={4}
                  mb={4}
                  bg={item.color}
                  color="white"
                  transition="0.3s ease all"
                  _hover={{
                    textDecoration: "none",
                    transform: "scale(1.1)",
                  }}
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <span
                    style={{ marginRight: "16px", fontSize: "28px" }}
                    aria-hidden
                  >
                    {item.icon}
                  </span>{" "}
                  {item.label}
                </Button>
              );
            })}
          </Box>
        </Stack>
      </Box>
      <Box
        w="400px"
        h="400px"
        borderRadius="400px"
        background="blue"
        position="absolute"
        bottom={-8}
        left={-8}
        zIndex={1}
        filter="blur(200px)"
        bgGradient={"linear(to-br, #5400ea, #e15f43)"}
      ></Box>
    </Flex>
  );
};

export default Contact;
